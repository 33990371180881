.header {
  position: fixed;
  z-index: 999;
  top: 0;
  header {
    background-color: transparent;
    box-shadow: none;
  }
  span {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-align: left;
  }
  p {
    font-family: 'Montserrat';
    color: #95a6b7;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: 0.15px;
    margin: 0 0 0 10px;
  }
  button.MuiIconButton-sizeSmall.MuiIconButton-edgeStart {
    margin: 0;
  }
  header div.MuiToolbar-regular {
    min-height: 80px;
  }
  header div.MuiToolbar-regular div {
    width: 100%;
    display: flex;
    margin: auto 0 10px 0;
  }
}
