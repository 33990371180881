.footerContent {
  position: fixed;
  z-index: 999;
  bottom: 0;
  width: 100%;
  height: 110px;
}
.content2 {
  height: 110px;
  width: 360px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (min-width: 425px) {
    width: 100%;
  }
  .fabButton {
    background-color: #ff3030;
    box-shadow: 0px 30px 30px rgba(148, 0, 0, 0.48);
    color: #fff;
    z-index: 1;
  }
  .fabButton.MuiFab-root:hover {
    background-color: #ff3030;
  }
}
.nav {
  margin-top: -20px;
  width: 100%;
  display: flex;
  padding-top: 10px;
  justify-content: space-between;
  background-image: url(../../assets/footer.svg);
  background-repeat: no-repeat;
  height: 58.4px;
  backdrop-filter: blur(1px);
  img {
    width: 18px;
    height: 18px;
  }
  @media (min-width: 425px) {
    background-color: #ff3030;
  }
}
.divider {
  background-color: #ff3030;
  height: 20px;
  display: flex;
  justify-content: center;
  hr {
    height: 2px;
    width: 72px;
    background: #ffffff;
    border-radius: 4px;
    margin: revert;
  }
}
.title {
  height: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
}

.menu {
  margin-left: 16px;
}

.listItem {
  height: 48px;
  span.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock {
    font-family: "Lato";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #4a545f;
  }
  span.MuiTypography-body1:hover,
  div.MuiListItemIcon-root:hover {
    color: #ff3030;
    border-radius: 8px;
    div.muilistitemicon-root {
      color: #ff3030;
    }
  }
}
