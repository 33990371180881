.startPageContent {
  margin: auto 16px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  .logo {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 132px;
    margin-bottom: 42px;
    span {
      background: #f1f7ff;
      box-shadow: 0px 33px 42px rgba(0, 0, 0, 0.08);
      height: 180px;
      width: 180px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
    }
    p {
      font-family: "Lato";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #4a545f;
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    button{
      margin-top: 24px;
    }
  }
  .m-t{
    margin-top: 24px;
  }
}
