.login {
  margin: auto 16px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 80px 0 0 0;
  align-items: center;
  min-width: 50%;
  h3 {
    text-align: center;
  }
}
.form {
  width: 100%;
  margin-top: 8px;
  div.MuiSelect-outlined {
    text-align: start;
  }
}

@media (min-width: 600px) {
  .login {
    width: 500px;
    margin: auto;
  }
}

.formRemamber {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  span a {
    color: #95a6b7;
  }
  span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
    color: #4a545f;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
}

.subTitle {
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  color: #95a6b7;
}

.messagers {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  img {
    width: 50px;
    height: 50px;
  }
}

.items.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
  padding-left: 0;
  padding-right: 0;
}

.fabButton {
  background-color: "transparent";
  width: 70px;
  height: 70px;
  .MuiFab-root{
    width: 50;
    height: 50;
  }
}
