.register {
  margin: auto 16px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 80px 0 0 0;
  h3 {
    text-align: center;
  }
}
.form {
  width: 100%;
  margin-top: 8px;
  div.MuiSelect-outlined {
    text-align: start;
  }
}

@media (min-width: 600px) {
  .register {
    width: 500px;
    margin: auto;
  }
}

.subTitle {
  font-family: "Roboto";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.10000000149011612px;
  color: #95a6b7;
}

.items.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
  padding-left: 0;
  padding-right: 0;
}
