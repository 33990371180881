@font-face {
  font-family: "Lato";
  src: local("Lato"), url(../../fonts/Lato-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url(../../fonts/Montserrat-Regular.ttf) format("truetype");
}

.App {
  text-align: center;
  font-family: "Lato";
  position: relative;
  height: 100vh;
  background-image: url(../../assets/bg_svg_1.svg);
  background-repeat: repeat-x;
  background-size: auto 100%;
}

.MuiTypography-root.MuiListItemText-primary.MuiTypography-body1.MuiTypography-displayBlock {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #281714;
}

.MuiTypography-root.MuiListItemText-secondary.MuiTypography-body2.MuiTypography-colorTextSecondary.MuiTypography-displayBlock {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #95a6b7;
  opacity: 0.6;
}
